/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 2/7/2020
 * @Example
 */
import React                         from "react";
import {connect}                     from "react-redux";
import {bindActionCreators, compose} from "redux";
import Config                        from "../../../../config";
import {Row, Col, FormGroup}         from "react-bootstrap"
import withStyles                    from "@material-ui/core/styles/withStyles";
import Approvals                     from "../../../approvals/approvals";
import * as ApprovalActions          from "../../../../redux/approvals/approvals_actions";
import LabelText                     from "../../../common/label-text/label-text";
import {Input}                       from "@material-ui/core";
import ActionToolbar                 from "../../../common/toolbar/action-toolbar";
import * as generalActions           from "../../../../redux/general/general_actions";
import UserImage                     from "../../../common/user/user-image";

const styles = theme => ({
    inputNoLine: {
        '&>div': {
            pointerEvent: 'none',
            '&:before': {
                display: 'none'
            }
        }
    }
});

class W09F2012 extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            iPermission: 0,
            loading:     false,
            dataForm:    {},
        };
    }

    loadPermission = async () => {
        await this.props.generalActions.getPermission("W09F2061", (isPer) => {
            this.setState({iPermission: isPer});
        });
    };

    loadFormInfo = (VoucherID, cb) => {
        const params = {
            DivisionID: Config.getDivisionID(),
            FormID:     "W09F2012",
            Language:   Config.language || "84",
            VoucherID:  VoucherID ? VoucherID : "",
        };
        this.setState({loading: true});
        this.props.approvalActions.getFormInfo(params, (error, data) => {
            this.setState({loading: false});
            if (error) {
                let message = error.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
            if (data) {
                this.setState({
                    dataForm: data
                });
                cb && cb(data);
            }
        });
    };

    componentDidMount = async () => {
        await this.loadPermission();
        if (!this.state.iPermission) return;
        // this.checkNotify();
    };

    //Form info..
    renderFormInfo = (data) => {
        const {classes} = this.props;
        const DateLeft   = Config.helpers.getObjectValue(data, 'DateLeft', '');
        const NoticeDate = Config.helpers.getObjectValue(data, 'NoticeDate', '');
        return (
            <>
                <FormGroup>
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <LabelText
                                allowPadding={true}
                                label={Config.lang("Nhan_vien")}
                                value={() => {
                                    return (
                                        <div className={"display_row align-center pdb5"}
                                             style={{
                                                 width:  "100%",
                                                 height: 37
                                             }}>
                                            <UserImage data={data} allowHover={false} width={32} height={32}/>
                                            <Input
                                                color={"primary"}
                                                readOnly={true}
                                                value={Config.helpers.getObjectValue(data, "EmployeeName", "")}
                                                disableUnderline={true}
                                            />
                                        </div>
                                    );
                                }}
                            />
                        </Col>

                        <Col xs={12} sm={12} md={6} lg={6}>
                            <LabelText
                                label={Config.lang("So_ngay_vi_pham")}
                                value={data?.ViolateDays ?? 0}
                                fullWidth
                                className={classes.inputNoLine}
                            />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <LabelText
                                label={Config.lang("Ngay_bat_dau_nghi_viec")}
                                value={Config.convertDate(DateLeft, "")}
                                fullWidth={true}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <LabelText
                                label={Config.lang("Ngay_nop_don")}
                                value={Config.convertDate(NoticeDate, "")}
                                fullWidth={true}
                            />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <LabelText
                                label={Config.lang("Hinh_thuc_nghi_viec")}
                                value={Config.helpers.getObjectValue(data, 'ResignFormName', '')}
                                fullWidth={true}
                            />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <LabelText
                                label={Config.lang("Ly_do")}
                                value={Config.helpers.getObjectValue(data, 'Reason', '')}
                                fullWidth={true}
                            />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <LabelText
                                label={Config.lang("Ghi_chu")}
                                value={Config.helpers.getObjectValue(data, 'Notes', '')}
                                fullWidth={true}
                            />
                        </Col>
                    </Row>
                </FormGroup>
            </>
        );
    };

    onSelectionChanged = (e) => {
        if (!e) return false;
        this.loadFormInfo(e.VoucherID);
    };

    onDrawerClosed = () => {
        this.setState({VoucherID: ""});
    };

    render () {
        const {iPermission, loading, dataForm, VoucherID} = this.state;
        if (!iPermission) return null;
        return (
            <React.Fragment>
                <FormGroup>
                    <ActionToolbar
                        title={Config.lang("Duyet_de_xuat_nghi_viec")}
                    />
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col xs={12}>
                            <Approvals
                                parentProps={this.props}
                                FormID={"W09F2012"}
                                dataForm={dataForm}
                                ref={'Approvals'}
                                selectedRowKey={VoucherID}
                                loading={loading}
                                singleClick
                                formInfoRender={this.renderFormInfo} //render form info with every screen
                                onSelectionChanged={this.onSelectionChanged} //action when select row...
                                onDrawerClosed={this.onDrawerClosed}

                            />
                        </Col>
                    </Row>
                </FormGroup>
            </React.Fragment>
        )
    }
}

export default compose(connect(null, (dispatch) => ({
    generalActions:  bindActionCreators(generalActions, dispatch),
    approvalActions: bindActionCreators(ApprovalActions, dispatch),
})), withStyles(styles))(W09F2012);
