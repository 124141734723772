/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 2/12/2020
 * @Example
 */
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import * as W09F2010Actions from "../../../../redux/W0X/W09F2010/W09F2010_actions";
import { bindActionCreators, compose } from "redux";
import Config from "../../../../config";
import { Row, Col, FormGroup } from "react-bootstrap";
import ButtonGeneral from "../../../common/button/button-general";
import withStyles from "@material-ui/core/styles/withStyles";
import { Combo, MForm, TextField } from "../../../common/form-material";
import * as generalActions from "../../../../redux/general/general_actions";
import moment from "moment";
import DateBoxPicker from "../../../common/form-material/date-box"
import Attachments from "../../../common/attachments/attachments";
import IconButton from "@material-ui/core/IconButton";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import Modal from "../../../common/modal/modal";
import _ from 'lodash';

const styles = {
    avatar: {
        width: 30,
        height: 30,
        borderRadius: "50%",
        border: "1px solid #ddd",
        overflow: "hidden",
        marginRight: 8
    },
    imgAvatar: {
        maxWidth: "100%",
        maxHeight: "100%",
        objectFit: "contain"
    },
    chip: {
        fontSize: "1rem",
        fontWeight: 400,
        border: "none",
        backgroundColor: "#EBEBEB"
    },
    inputNoLine:{
        '&>div':{
            pointerEvent:'none',
            '&:before':{
                display:'none'
            }
        }
    }
};

class W09F2010Popup extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            showW09F2010Popup: false,
            dataGrid: [],
            disabled: false,
            error: {},
            loading: false,
            uploading: false,
            dataOldAttachments: [],
            dataForm: {
                Employee: null,
                DateLeft: null,
                NoticeDate: null,
                ResignFormID: "",
                Notes: "",
                ReasonID: "",
                ViolateDays: 0,
            },
            dataHover: null,
            dataCboEmployees: {
                rows: [],
                total: 0
            },
            loadEmployees: false,
            disabledCboEmployee: false,
        };
        this.attachments = [];
        this.deletedFile = [];
        this.filterCboEmployees = {
            timer: null,
            strSearch: "",
            skip: 0,
            limit: 20
        };
    }

    loadCboResignForm = () => {
        const param = { Language: Config.language || "84" };

        this.props.w09f2010Actions.loadCboResignForm(param, error => {
            if (error) {
                Config.popup2.show("ERROR", error);
                return false;
            }
        });
    };

    loadCboReason = () => {
        const param = { Language: Config.language || "84", TypeID: 2 };

        this.props.w09f2010Actions.getCboReason(param, error => {
            if (error) {
                Config.popup2.show("ERROR", error);
                return false;
            }
        });
    };

    loadForm = () => {
        const { data } = this.props;
        const param = {
            LeaveTransID: data && data.LeaveTransID ? data.LeaveTransID : ""
        };
        this.setState({ loading: true });
        this.props.w09f2010Actions.loadForm(param, (error, data) => {
            this.setState({ loading: false });
            if (error) {
                Config.popup2.show("ERROR", error);
                return false;
            }
            if (data) {
                const user = Config.getUser({ EmployeeID: data.EmployeeID });
                this.setState({
                    dataForm: {
                        ...data,
                        Employee: user ? user : data
                    }
                });
            }
        });
    };

    loadRequiredFields = () => {
        const params = {
            FormID: 'W09F2010',
            ModuleID: '09',
            CheckMode: 0
        };
        this.setState({ loading: true });
        this.props.generalActions.getRequiredFields(params, error => {
            this.setState({ loading: false });
            if (error) {
                Config.popup2.show("ERROR", error);
                return false;
            }
        });
    };

    handleChange = (key, e) => {
        if (!key || !e) return false;
        if (key !== "EmployeeID") {
            this._setDataForm(key, e.value);
        } else {
            this._setDataForm("Employee", e.value);
        }
    };

    componentDidMount() {
        const { mode, type, data, dataForm } = this.props;
        this.loadCboResignForm();
        this.loadCboReason();
        this.loadCboEmployees();
        this.loadRequiredFields();
        if (mode === "view") {
            this.loadForm();
            this.loadAttachments();
            if (data && (Number(data.AppStatusID) !== 0)) {
                this.setState({ disabled: true });
            }
        } else {
            if (!type || type === "ESS") {
                this.setState({
                    disabledCboEmployee: true,
                }, () => {
                    const EmployeeID = Config.getHREmployeeID();
                    const user       = Config.getUser({EmployeeID});
                    if (user) {
                        this.setState({
                            dataForm: {
                                ...dataForm,
                                Employee: {
                                    EmployeeID:     user.EmployeeID ? user.EmployeeID : "",
                                    EmployeeName:   user.EmployeeName ? user.EmployeeName : "",
                                    UserPictureURL: user.UserPictureURL ? user.UserPictureURL : "",
                                }
                            }
                        })
                    }
                });
            }
        }
    }

    _setDataForm = (key, value, cb) => {
        if (!key) return false;
        this.setState({
            dataForm: {
                ...this.state.dataForm,
                [key]: value
            }
        }, () => {
            if (cb) cb();
            const {dataForm} = this.state;
            // if onchange datetime picker DateLeft || NoticeDate
            if((key === 'DateLeft' || key === 'NoticeDate') && dataForm.NoticeDate && dataForm.DateLeft){
                this.getViolateDays();
            }
        });
    };

    /**
     * Call api get data ViolateDays in master form
     * no params
     */
    getViolateDays = () => {
        const {dataForm:{DateLeft, NoticeDate, Employee}} = this.state;
        const params = {
            FormID: 'W09F2010',
            Language: Config.language || "84",
            Mode: 0,
            EmployeeID: Employee?.EmployeeID,
            NoticeDate,
            DateLeft
        };
        this.props.w09f2010Actions.getViolateDays(params, (error, data) => {
            this.setState({ loading: false });
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            this.setState({
                dataForm: {
                    ...this.state.dataForm,
                    ViolateDays: data?.ViolateDays ?? 0
                }
            })
        });
    };

    setStateErrorText(objValue, key) {
        const stateError = key ? key : "error";
        this.setState({
            [stateError]: {
                ...(this.state[stateError] ? this.state[stateError] : {}),
                ...objValue
            }
        });
        return Object.keys(objValue).length !== 0;
    }

    onSave = () => {
        const { mode, data, getRequiredFields } = this.props;
        const isEdit = mode === "view";
        const { dataForm, dataOldAttachments } = this.state;
        const listDataForm = Object.keys(dataForm);
        let dataRequired = ["EmployeeID", "DateLeft", "NoticeDate"];
        const filterAttachment = getRequiredFields && getRequiredFields.filter(item =>
            item.SqlFieldName === 'Attachment'
        );
        getRequiredFields && getRequiredFields.forEach((field) => {
            if ((field.ValidMode && field.ValidMode === "O"
                && field.SqlFieldName && listDataForm.indexOf(field.SqlFieldName) > -1
                && dataRequired.indexOf(field.SqlFieldName) < 0)
                || ((filterAttachment[0].ValidMode === "O" && _.isEmpty(this.attachments) && _.isEmpty(dataOldAttachments)) ? (field.SqlFieldName === 'Attachment') : '')
            ) {
                dataRequired.push(field.SqlFieldName);
            }
        });

        //validate form..
        const validateRules = [];
        dataRequired && dataRequired.forEach((item) => {
            let rule = {};
            switch (item) {
                case "EmployeeID":
                    rule = {
                        name: "EmployeeID",
                        rules: ["isRequired"],
                        value: dataForm && dataForm.Employee && dataForm.Employee.EmployeeID ? dataForm.Employee.EmployeeID : ""
                    };
                    break;
                case "DateLeft":
                    rule = {
                        name: "DateLeft",
                        rules: ["isRequired"],
                        value: dataForm && dataForm.DateLeft ? dataForm.DateLeft : ""
                    };
                    break;
                case "NoticeDate":
                    rule = {
                        name: "NoticeDate",
                        rules: ["isRequired"],
                        value: dataForm && dataForm.NoticeDate ? dataForm.NoticeDate : ""
                    };
                    break;
                default:
                    rule = {
                        name: item,
                        rules: ["isRequired"],
                        value: dataForm[item]
                    };
                    break;
            }
            validateRules.push(rule);
        });
        const validateForm = MForm.formValidation(validateRules);
        if (Object.keys(validateForm).length > 0) {
            this.setStateErrorText(validateForm);
            return false;
        } else {
            const arrAttachments = this._getAttachments();
            const Language = Config.language || "84";
            const DivisionID = Config.getDivisionID();
            const TranMonth = Config.getHRTransMonth();
            const TranYear = Config.getHRTransYear();
            const params = {
                // ProTransID: data && data.ProTransID ? data.ProTransID : "",
                // ProposalDate: data && data.ProposalDate ? data.ProposalDate : "",
                // ProTeamID: "",
                // ProDepartmentID: "",
                // ProAuthority: "",
                // ProResponsibility: "",
                // ProDivisionID: "",
                LeaveTransID: data && data.LeaveTransID ? data.LeaveTransID : "",
                ...dataForm,
                EmployeeID: dataForm && dataForm.Employee && dataForm.Employee.EmployeeID ? dataForm.Employee.EmployeeID : "",
                Language,
                DivisionID,
                TranMonth,
                TranYear,
                arrAttachment: JSON.stringify(arrAttachments),
                ViolateDays: dataForm?.ViolateDays ?? 0
            };
            delete params.Employee;

            this.setState({ loading: true });
            this.props.w09f2010Actions[isEdit ? "edit" : "save"](params, (error, data) => {
                this.setState({ loading: false });
                if (error) {
                    let message = Config.lang("Loi_chua_xac_dinh");
                    switch (error.code) {
                        case "F2000E002":
                            message = "Mode " + Config.lang("Bat_buoc");
                            break;
                        case "F2000E003":
                            message = "LeaveTypeID " + Config.lang("Bat_buoc");
                            break;
                        case "F2000E004":
                            message = "IsRegisterType " + Config.lang("Bat_buoc");
                            break;
                        case "F2000E001":
                            message = Config.lang("Luu_khong_thanh_cong");
                            break;
                        default:
                            break;
                    }
                    Config.popup2.show("INFO", message);
                    return false;
                }
                else if (data.Status && data.Status === 1) {
                    let message = data.Message || Config.lang("Loi_chua_xac_dinh");
                    Config.popup.show('INFO', message);
                    return false;
                } else {
                    Config.notify.show('success', Config.lang("Luu_thanh_cong"), 2000);
                    this.props.onClose(true);
                }
            }
            );
        }

    };

    onAttachment = () => {
        if (this.attRef) {
            this.attRef.onAttachment();
        }
    };

    _getAttachments = () => {
        const listAttachments = Config.helpers.getFileInfomations(this.attachments);
        const { dataOldAttachments } = this.state;

        let arrAttachment = [];
        listAttachments.forEach((att) => {
            arrAttachment.push({
                URL: att.url ? att.url : "",
                FileName: att.fileName ? att.fileName : "",
                FileSize: att.fileSize ? att.fileSize : "",
                FileExt: att.fileExt ? att.fileExt : ""
            });
        });
        if (dataOldAttachments && dataOldAttachments.length > 0) {
            arrAttachment = dataOldAttachments.concat(arrAttachment);
        }

        return arrAttachment;
    };

    loadAttachments = () => {
        const { data } = this.props;
        const param = {
            KeyID: data && data.LeaveTransID ? data.LeaveTransID : "",
        };
        this.props.generalActions.getAttachmentsByTransID(param, (error, data) => {
            if (error) {
                Config.popup2.show("ERROR", error);
                return false;
            }
            if (data && data.length > 0) {
                this.setState({
                    dataOldAttachments: data
                });
            }
        });
    };

    onUploading = (value) => {
        this.setState({ uploading: value });
    };

    onChangeAttachments = (e) => {
        this.attachments = e.uploadedFiles ? e.uploadedFiles : [];
        if (e.deletedFiles && e.deletedFiles.length > 0) {
            this.deletedFile = [...e.deletedFiles];
            const _arrRemove = this.deletedFile.map((d) => d.AttachmentID);
            this.setState(prevState => ({
                dataOldAttachments: prevState.dataOldAttachments.filter((att) => {
                    return _arrRemove.indexOf(att.AttachmentID) < 0;
                })
            }));
        }
    };

    loadCboEmployees = (isReset) => {
        const { mode, type } = this.props;
        const EmployeeID = Config.getHREmployeeID();
        const user       = Config.getUser({EmployeeID});
        const param = {
            Type: "EmployeeID",
            FormID: "W09F2010",
            Mode: mode === "add" ? 2 : 0,
            Language: Config.language || "84",
            skip: this.filterCboEmployees.skip,
            limit: this.filterCboEmployees.limit,
            search: !type ? (user?.EmployeeID || "") : this.filterCboEmployees.strSearch,
            search2: "",
            searchUC: ""
        };
        this.setState({ loadEmployees: true });
        this.props.generalActions.getCboEmployees(param, (error, data) => {
            this.setState({ loadEmployees: false });
            if (error) {
                Config.popup2.show("ERROR", error);
                return false;
            }
            if (data) {
                const { dataCboEmployees } = this.state;
                const rows = data && data.rows ? data.rows : data;
                const total = data && data.total ? data.total : data.length;
                this.setState({
                    dataCboEmployees: {
                        rows: isReset ? rows : dataCboEmployees.rows.concat(rows),
                        total: total
                    }
                })
            }
        });
    };

    render() {
        let { getCboResignForm, getCboReason, mode, classes } = this.props;
        const { uploading, dataForm, error, loading, dataOldAttachments, disabled, dataHover,
            dataCboEmployees, loadEmployees, disabledCboEmployee
        } = this.state;

        return (
            <>
                <Modal.Content>
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <Combo.Employees
                                dataSource={dataCboEmployees}
                                skip={this.filterCboEmployees.skip}
                                limit={this.filterCboEmployees.limit}
                                acceptCustomValue={true}
                                error={error && error["EmployeeID"]}
                                displayExpr={'EmployeeName'}
                                keyExpr={'EmployeeID'}
                                valueExpr={'EmployeeID'}
                                subTextExpr={"EmployeeID"}
                                value={dataForm.Employee}
                                stylingMode={'underlined'}
                                loading={loadEmployees}
                                shrink={true}
                                required={true}
                                isHover={true}
                                disabled={mode === "view" || loading || disabled || disabledCboEmployee}
                                label={Config.lang('Nhan_vien')}
                                onValueChanged={e => this.handleChange("EmployeeID", e)}
                                onInputChanged={(e) => {
                                    this.filterCboEmployees.strSearch = e.target.value;
                                    this.filterCboEmployees.skip = 0;
                                    this.loadCboEmployees(true);
                                }}
                                onLoadMore={(e) => {
                                    this.filterCboEmployees.skip = e.skip;
                                    this.filterCboEmployees.limit = e.limit;
                                    this.loadCboEmployees();
                                }}
                                onHoverAvatar={(data) => {
                                    this.setState({
                                        dataHover: data
                                    })
                                }}
                            />
                            {dataHover &&
                                <div className={'hover-employee'}>
                                    {Config.lang('Phong_ban') + ': ' + dataHover.DepartmentName}<br />
                                    {Config.lang('Du_an') + ': ' + dataHover.ProjectName}<br />
                                    {Config.lang('Chuc_vu') + ': ' + dataHover.DutyName}<br />
                                    {Config.lang('Ngay_vao_lam') + ': ' + (dataHover.DateJoined ? moment(dataHover.DateJoined).format('DD/MM/YYYY') : '---')}
                                </div>}
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <TextField
                                label={Config.lang("So_ngay_vi_pham")}
                                variant={"standard"}
                                margin={"normal"}
                                value={dataForm?.ViolateDays ?? 0}
                                style={{marginTop: 8}}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{readOnly: true}}
                                fullWidth
                                className={classes.inputNoLine}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <DateBoxPicker
                                error={error && error["DateLeft"]}
                                label={Config.lang("Ngay_bat_dau_nghi_viec")}
                                placeholder={Config.lang("Chon_ngay")}
                                useMaskBehavior={true}
                                width={"100%"}
                                stylingMode={"underlined"}
                                shrink={true}
                                required={true}
                                value={dataForm.DateLeft}
                                style={{ height: 36 }}
                                onValueChanged={e => this.handleChange("DateLeft", e)}
                                disabled={disabled || loading}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <DateBoxPicker
                                error={error && error["NoticeDate"]}
                                label={Config.lang("Ngay_nop_don")}
                                placeholder={Config.lang("Chon_ngay")}
                                useMaskBehavior={true}
                                width={"100%"}
                                stylingMode={"underlined"}
                                shrink={true}
                                required={true}
                                value={dataForm.NoticeDate}
                                style={{ height: 36 }}
                                onValueChanged={e => this.handleChange("NoticeDate", e)}
                                disabled={disabled || loading}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <Combo
                                error={error && error["ResignFormID"]}
                                dataSource={getCboResignForm}
                                displayExpr={"ResignFormName"}
                                valueExpr={"ResignFormID"}
                                subTextExpr={"ResignFormID"}
                                stylingMode={"underlined"}
                                disabled={disabled || loading}
                                searchEnabled={true}
                                shrink={true}
                                value={dataForm.ResignFormID}
                                label={Config.lang("Hinh_thuc_nghi_viec")}
                                onValueChanged={e =>
                                    this.handleChange("ResignFormID", e)
                                }
                                margin={"normal"}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <Combo
                                error={error && error["ReasonID"]}
                                dataSource={getCboReason}
                                displayExpr={"ReasonName"}
                                valueExpr={"ReasonID"}
                                subTextExpr={"ReasonID"}
                                stylingMode={"underlined"}
                                disabled={disabled || loading}
                                searchEnabled={true}
                                shrink={true}
                                value={dataForm.ReasonID}
                                label={Config.lang("Ly_do")}
                                onValueChanged={e =>
                                    this.handleChange("ReasonID", e)
                                }
                                margin={"normal"}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <TextField
                                error={error && error["Notes"]}
                                label={Config.lang("Ghi_chu")}
                                variant={"standard"}
                                margin={"normal"}
                                value={dataForm?.Notes || ""}
                                disabled={disabled || loading}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                helperText={this.state.helperTextQuantity}
                                onChange={e => this.handleChange("Notes", e.target)}
                                fullWidth
                            />
                        </Col>
                    </Row>
                    <FormGroup style={{ marginBottom: 34 }}>
                        <Attachments
                            error={error && error["Attachment"]}
                            ref={ref => this.attRef = ref}
                            showButton={true}
                            files={dataOldAttachments}
                            disabled={disabled || loading || uploading}
                            maxLength={5}
                            uploading={loading}
                            onUploading={this.onUploading}
                            onChanged={this.onChangeAttachments}
                        />
                    </FormGroup>
                </Modal.Content>
                <Modal.Actions style={{ justifyContent: "space-between" }}>
                    <div>
                        <IconButton aria-label="view" disabled={loading || uploading} onClick={this.onAttachment}>
                            <AttachFileIcon />
                        </IconButton>
                    </div>
                    <div>
                        <ButtonGeneral
                            name={Config.lang("Luu")}
                            typeButton={"save"}
                            disabled={loading || disabled || uploading}
                            style={{ textTransform: "uppercase" }}
                            size={"large"}
                            onClick={this.onSave}
                        />
                    </div>

                </Modal.Actions>
            </>
        );
    }
}

W09F2010Popup.propTypes = {
    open: PropTypes.bool,
    mode: PropTypes.string,
    data: PropTypes.any,

    onClose: PropTypes.func
};

export default compose(
    connect(
        state => ({
            getCboEmployees: state.general.getCboEmployees,
            getLeaveTypes: state.W09F2010.getLeaveTypes,
            loadForm: state.W09F2010.loadForm,
            getCboDisRewardForm: state.W09F2010.getCboDisRewardForm,
            getCboProDisRewardLevel: state.W09F2010.getCboProDisRewardLevel,
            getCboDisViolationActGroup: state.W09F2010.getCboDisViolationActGroup,
            getCboDisViolationAct: state.W09F2010.getCboDisViolationAct,
            getRewardCaption: state.W09F2010.getRewardCaption,
            getForm: state.W09F2010.getForm,
            getCboResignForm: state.W09F2010.getCboResignForm,
            getCboReason: state.W09F2010.getCboReason,
            getRequiredFields: state.general.getRequiredFields,
            getAttachmentsByTransID: state.general.getAttachmentsByTransID
        }),
        dispatch => ({
            w09f2010Actions: bindActionCreators(W09F2010Actions, dispatch),
            generalActions: bindActionCreators(generalActions, dispatch)
        })
    ),
    withStyles(styles)
)(W09F2010Popup);
