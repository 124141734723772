/**
 * @copyright 2019 @ DigiNet
 * @author THIEN
 * @create 12/5/2019
 * @Example
 */

import React, { Component } from "react";

import PropTypes from "prop-types";

import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";

import * as w09f2005Action from "../../../../redux/W0X/W09F2005/W09F2005_actions";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import { FormGroup} from "react-bootstrap";
import { Tabs, Tab } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import Config from "../../../../config";

const styles = theme => ({
  indicator: {
    borderRadius: 40,
  },
});
class W09F2005Tabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hoverButtonEdit: false,
      hoverButtonExport: false,
      tabStatus: []
    };
  }

  loadStatusTab = () => {
    this.props.w09f2005Action.getTabStatus({Language: Config.language || "84"}, (error, data) => {
      if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
      this.setState({ tabStatus: data });
    });
  };

  componentDidMount = () => {
    this.loadStatusTab();
  };

  renderTab = () => {
    const {iPermissions} = this.props;
    const { tabStatus } = this.state;
    return tabStatus && tabStatus.map(({ StatusType, StatusName, Total, FormID }, i) => {
      if (!FormID || (FormID && (!iPermissions || !iPermissions[FormID]))) return null;
      return <Tab
          key={i}
          value={StatusType}
          label={`${Config.lang(StatusName)} (${Total})`}
      />
    });
  };

  render() {
    const { tabIndex, onChangeTab, classes: { indicator } } = this.props;
    return (
      <>
        <FormGroup style={{ marginBottom: 0 }}>
          <ActionToolbar title={Config.lang("DHR_Ho_So_Nhan_Vien")}>
              {/*<ButtonGeneral*/}
              {/*  typeButton={"excel"}*/}
              {/*  name={Config.lang("DHR_Xuat")}*/}
              {/*  color={"primary"}*/}
              {/*  typeIcon={"svg"}*/}
              {/*  variant={"text"}*/}
              {/*  style={{ textTransform: "uppercase" }}*/}
              {/*  size={"large"}*/}
              {/*/>*/}
              {/*<ButtonGeneral*/}
              {/*  name={Config.lang("DHR_Chinh_Sua")}*/}
              {/*  typeButton={"edit"}*/}
              {/*  typeIcon={"svg"}*/}
              {/*  color={"primary"}*/}
              {/*  variant={"text"}*/}
              {/*  style={{ textTransform: "uppercase" }}*/}
              {/*  size={"large"}*/}
              {/*/>*/}
          </ActionToolbar>
        </FormGroup>
        <Tabs
          classes={{ indicator }}
          indicatorColor="primary"
          textColor="primary"
          value={tabIndex}
          onChange={onChangeTab}
        >
          {this.renderTab()}
        </Tabs>
      </>
    );
  }
}

W09F2005Tabs.propTypes = {
  classes: PropTypes.any,
  onChangeTab: PropTypes.any,
  tabIndex: PropTypes.any,
  iPermissions: PropTypes.any,
  w09f2005Action: PropTypes.any
};

export default compose(
  connect(
    state => ({ listEmployees: state.W09F2005.listEmployees }),
    dispatch => ({
      w09f2005Action: bindActionCreators(w09f2005Action, dispatch)
    })
  ),
  withStyles(styles)
)(W09F2005Tabs);
