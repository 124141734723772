import React, {useState, useEffect, useRef} from "react";
import {bindActionCreators, compose} from "redux";
import {connect} from "react-redux";
import {FormLabel as Label, withStyles} from "@material-ui/core";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import Config from "../../../../config";
import GridContainer from "../../../grid-container/grid-container";
import {Column} from "devextreme-react/data-grid";
import Api from "../../../../services/api";
import _ from "lodash";
import UserImage from "../../../common/user/user-image";
import UserName from "../../../common/user/user-name";
import {Row, Col, FormGroup} from "react-bootstrap"
import Status from "../../../common/status/status";
import IconButton from "@material-ui/core/IconButton";
import HistoryIcon from "@material-ui/icons/History";
import Divider from "@material-ui/core/Divider";
import Icons from "../../../common/icons";
import Filter from "../../../filter/filter";
import {Combo} from "../../../common/form-material";
import * as generalActions from "../../../../redux/general/general_actions";
import DateBoxPicker from "../../../common/form-material/date-box";
import ButtonGeneral from "../../../common/button/button-general";
import W09F2205 from "../W09F2205/W09F2205";
import W09F2215 from "../W09F2215/W09F2215";
import W84F3005 from "../../../W8X/W84/W84F3005/W84F3005";
import {Image} from "react-bootstrap";
import moment from "moment";
import {browserHistory} from "react-router";

const styles = () => {
    return ({
        divAvatar: {
            width: 46,
            height: 46,
            minWidth: 46,
            minHeight: 46,
            borderRadius: '50%',
            overflow: 'hidden',
            marginRight: 8
        },
        divText: {
            fontWeight: 500,
        },
        dateInfo: {
            display: "flex",
            alignItems: "center"
        },
    })
};

const W09F2002 = ({classes, generalActions, getCboAppStatus, location}) => {
    const [dataGrid, setDataGrid] = useState({rows: [], total: 0});
    const [dataCboProperty, setCboProperty] = useState([]);
    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);

    const [isLoadingGrid, setLoadingGrid] = useState(false);
    const [isLoadingCboProperty, setLoadingCboProperty] = useState(false);
    const [isLoadingCboAppStatus, setLoadingCboAppStatus] = useState(false);

    const [isShowW092205, setShowW09F2205] = useState(false);
    const [isShowW092215, setShowW09F2215] = useState(false);
    const [isShowW84F3005, setShowW84F3005] = useState(false);

    const [rowDataSelected, setRowDataSelected] = useState({});

    const paramsFromProps = {
        EmployeeID: _.get(location, "state.EmployeeID", ""),
        TransID: _.get(location, "state.TransID", "")
    };



    const paramsFilter = {
        propertyID: useRef(null),
        approvalStatus: useRef(null),
    };

    const paramsPaging = {
        skip: 0,
        limit: 10,
    };

    const timerLoading = useRef(0);

    useEffect(() => {
        const {KeyID01} = Config.getUrlParams({location});
        Config.callChildForm({
            FormID: "W09F2002",
            // ID: voucher_id,
            keyExpr: "TransID",
            data: dataGrid && dataGrid.rows ? dataGrid.rows : [],
            onLoad: (params) => loadDataGrid(params && params.ID, KeyID01),
            onAction: (e, data) => {
                onOpenModal(getModalType(_.get(data, "PropertyID", "")), data, "view")
            }
        }, {location});
    });

    useEffect(() => {
        loadDataGrid();
        return () => {
            if (timerLoading.current) clearTimeout(timerLoading.current)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getModalType = (propertyID) => _.chain(propertyID).eq("RELATIVE").toNumber().value();

    const formatDate = (date, typeFormat = "DD/MM/YYYY") => {
        if (!date) return "";
        if (moment(date).isValid()) {
            return moment.utc(date).format(typeFormat);
        }
        return "";
    };

    const handleChangeFilter = (key, e) => {
        const dateField = ["dateFrom", "dateTo"];
        const value = _.get(e, "value", _.get(e, "target.value", ""));
        if (_.includes(dateField, key)) {
            key === "dateFrom" ? setDateFrom(value) : setDateTo(value);
            return true;
        }
        paramsFilter[key].current = value;
    };

    const handleResponse = (res) => {
        const resData = _.get(res, "data", []);
        const resMessageErr = _.get(res, "message", _.get(resData, "Message", ""));
        if (resMessageErr) {
            Config.popup.show("ERROR", resMessageErr);
            return false;
        }
        return resData;
    };

    const loadDataGrid = async (VoucherID, EmployeeID) => {
        const voucher_id = _.get(location.state, "voucher_id", "");
        VoucherID = VoucherID ? VoucherID : voucher_id;
        if (EmployeeID) paramsFromProps.EmployeeID = EmployeeID;
        if (VoucherID) paramsFromProps.TransID = VoucherID;
        const params = _.pickBy({
            Language: Config.language || "84",
            FormID: "W09F2002",
            DateFrom: dateFrom,
            DateTo: dateTo,
            ApprovalStatus: paramsFilter.approvalStatus.current,
            PropertyID: paramsFilter.propertyID.current,
            ...paramsFromProps,
            ...paramsPaging,
        }, (param) => !_.isNull(param));
        setLoadingGrid(true);
        const res = await Api.put("/w09f2002/get-detail", params);
        timerLoading.current = setTimeout(() => {
            setLoadingGrid(false);
        }, 300)
        const resData = handleResponse(res)
        if (resData) {
            setDataGrid(resData)
        }
    };

    const loadCboProperty = async () => {
        setLoadingCboProperty(true);
        const res = await Api.put("/w09f2002/get-property", {Language: Config.language || "84"});
        setLoadingCboProperty(false);
        const resData = handleResponse(res);
        if (resData) {
            setCboProperty(resData);
        }
    };

    const loadCboAppStatus = () => {
        const params = {
            Language: Config.language || "84",
            FormID: "STANDARD",
        };
        setLoadingCboAppStatus(true);
        generalActions.getCboAppStatus(params, () => {
            setLoadingCboAppStatus(false);
        });
    };

    const setShowModal = (type, value) => {
        switch (type) {
            case 0:
                setShowW09F2205(value);
                break;
            case 1:
                setShowW09F2215(value)
                break;
            case 2:
                setShowW84F3005(value)
                break;
            default:
                break;
        }
    };

    const onBack = () => {
        browserHistory.push(Config.getRootPath() + "W09F2000");
    }

    const onOpenModal = (type, dataRow = {}) => {
        setRowDataSelected(dataRow);
        setShowModal(type, true);
    };

    const onCloseModal = (type, isReload = false) => {
        if (isReload) loadDataGrid();
        setShowModal(type, false);
    };

    const onOpenFilter = () => {
        loadCboProperty();
        loadCboAppStatus();
    };

    const onChangePage = (page) => {
        paramsPaging.skip = page * paramsPaging.limit;
        loadDataGrid();
    };

    const onChangePerPage = (perPage) => {
        paramsPaging.skip = 0;
        paramsPaging.limit = perPage;
        loadDataGrid();
    };

    const onDelete = (data) => {
        Config.popup.show("YES_NO", Config.lang("DHR_Ban_co_chac_muon_xoa?"), async () => {
            const res = await Api.delete("/w09f2002/delete", {TransID: _.get(data, "TransID", "")});
            const resData = handleResponse(res);
            if (_.get(resData, "Status", 1) === 0) {
                Config.notify.show('success', Config.lang("DHR_Luu_thanh_cong"), 2000);
                loadDataGrid();
            }
        });
    };

    const renderEmpProfile = ({data}) => {
        const createDate  = formatDate(_.get(data, "CreateDate", ""), "DD/MM/YYYY kk:mm:ss");
        const departmentName =_.get(data, "DepartmentName", "");
        return <div className={"display_row align-center pdt10 pdb10"}>
            <div className={classes.divAvatar}>
                <UserImage keyExpr={"CreateUserID"} valueExpr={"UserID"} data={data}/>
            </div>
            <div className={"w75f2000_info"}>
                <div className={classes.divText}>
                    <UserName keyExpr={"CreateUserID"} valueExpr={"UserID"} data={data}/>
                </div>
                {departmentName && <div>
                    {departmentName}
                </div>}
                {createDate && <div className={classes.dateInfo}>
                    <Image src={require("../../../../assets/images/icon-calendar.svg")}/>
                    <div style={{paddingLeft: "8px"}} className={"date-text-info"}>
                        {createDate}
                    </div>
                </div>}
            </div>
        </div>
    };

    const renderInfo = ({data}) => {
        const arrDataRender = _.filter([
            {
                title: Config.lang("DHR_Ma_nhan_vien"),
                dataContent: paramsFromProps.EmployeeID,
            },
            {
                title: Config.lang("DHR_Ten_nhan_vien"),
                dataContent: _.get(Config.getUser({EmployeeID: paramsFromProps.EmployeeID}), "EmployeeName", ""),
            },
            {
                title: Config.lang("DHR_Ghi_chu"),
                dataContent: _.get(data, "Notes", ""),
            },
            {
                title: Config.lang("DHR_Thong_tin_dieu_chinh"),
                dataContent: _.get(data, "PropertyName", ""),
            }
        ],(item) => Boolean(item.dataContent));
        return (
            <div className={"pdt10 pdb10 " + classes.divText}>
                {_.map(arrDataRender, (item, i) => (
                    <div key={i}>
                        {item.title}: {item.dataContent}
                    </div>
                ))}
            </div>
        );
    };

    const renderStatus = ({data}) => {
        return <Status data={data}/>;
    };

    const renderAction = ({data}) => {
        return <div className={"display_row align-center"}>
            <IconButton aria-label="view" size="small" onClick={() => onOpenModal(2, data)}>
                <HistoryIcon/>
            </IconButton>
            <Divider orientation="vertical" style={{width: 2, height: 11, margin: '0 5px'}}/>
            <IconButton aria-label="view" size="small"
                        onClick={() => onOpenModal(getModalType(_.get(data, "PropertyID", "")), data)}>
                <Icons name={"view"}/>
            </IconButton>
            <Divider orientation="vertical" style={{width: 2, height: 11, margin: '0 5px'}}/>
            <IconButton disabled={!(!_.isNil(data?.AppStatusID) ? _.toNumber(data?.AppStatusID) === 0 : false)} aria-label="delete" size="small" onClick={() => onDelete(data)}>
                <Icons name={"delete"}/>
            </IconButton>
        </div>
    };

    const renderFilter = () => {
        return <Filter
            placeholder={Config.lang("DHR_Noi_dung_can_tim_kiem")}
            onOpenLoaded={onOpenFilter}
            renderFilter={() =>
                <>
                    <FormGroup>
                        <Row>
                            <Col xs={12}>
                                <Combo
                                    dataSource={getCboAppStatus}
                                    showClearButton={true}
                                    displayExpr={"AppStatusName"}
                                    valueExpr={"AppStatusID"}
                                    value={paramsFilter.approvalStatus.current}
                                    stylingMode={"outlined"}
                                    loading={isLoadingCboAppStatus}
                                    label={Config.lang("DHR_Trang_thai_duyet")}
                                    onValueChanged={(e) => handleChangeFilter("approvalStatus", e)}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <Combo
                                    dataSource={dataCboProperty}
                                    showClearButton={true}
                                    displayExpr={"PropertyName"}
                                    valueExpr={"PropertyID"}
                                    loading={isLoadingCboProperty}
                                    value={paramsFilter.propertyID.current}
                                    stylingMode={"outlined"}
                                    label={Config.lang("DHR_Dieu_chinh")}
                                    onValueChanged={(e) => handleChangeFilter("propertyID", e)}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <Label>{Config.lang("DHR_Ngay_dieu_chinh")}</Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={6}>
                                <div className={"display_row align-center"}>
                                    <Label className={"mgr5"}>{Config.lang("DHR_Tu")}</Label>
                                    <DateBoxPicker
                                        placeholder={"dd/mm/yyyy"}
                                        dateBoxProps={{max: dateTo}}
                                        useMaskBehavior={true}
                                        showClearButton={true}
                                        width={"100%"}
                                        value={dateFrom}
                                        onValueChanged={e => handleChangeFilter("dateFrom", e)}
                                    />
                                </div>
                            </Col>
                            <Col xs={12} sm={6}>
                                <div className={"display_row align-center"}>
                                    <Label className={"mgr5"}>{Config.lang("DHR_Den")}</Label>
                                    <DateBoxPicker
                                        dateBoxProps={{min: dateFrom}}
                                        placeholder={"dd/mm/yyyy"}
                                        useMaskBehavior={true}
                                        showClearButton={true}
                                        width={"100%"}
                                        value={dateTo}
                                        onValueChanged={e => handleChangeFilter("dateTo", e)}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup className={"display_row align-center valign-middle"}>
                        <ButtonGeneral
                            name={Config.lang("DHR_Tim_kiem")}
                            typeButton={"search"}
                            size={"large"}
                            color={"primary"}
                            variant={"outlined"}
                            onClick={loadDataGrid}
                        />
                    </FormGroup>
                </>
            }
        />
    };

    return <div>
        {isShowW092205 && <W09F2205
            onClose={(isReload) => onCloseModal(0, isReload)}
            open={isShowW092205}
            mode={"edit"}
            rowData={rowDataSelected}
        />}
        {isShowW092215 && <W09F2215
            onClose={(isReload) => onCloseModal(1, isReload)}
            open={isShowW092215}
            mode={"edit"}
            rowData={rowDataSelected}
        />}
        {isShowW84F3005 && <W84F3005 open={isShowW84F3005}
                                     onClose={(isReload) => onCloseModal(2, isReload)}
                                     FormID="W09F2002"
                                     TransID={_.get(rowDataSelected, "TransID", "")}
        />}
        <div className={"hidden"}>{renderFilter()}</div>
        <ActionToolbar  onBack={onBack} title={Config.lang("DHR_Lich_su_dieu_chinh_thong_tin")}/>
        <GridContainer
            style={{border: "none"}}
            dataSource={_.get(dataGrid, "rows", [])}
            showRowLines={true}
            showBorders={false}
            showColumnLines={false}
            showColumnHeaders={false}
            rowAlternationEnabled={false}
            onDbCellClick={({data}) => onOpenModal(getModalType(_.get(data, "PropertyID", "")), data)}
            noDataText={Config.lang("DHR_No_data")}
            loading={isLoadingGrid}
            hoverStateEnabled={true}
            totalItems={_.get(dataGrid, "total", 0)}
            itemPerPage={paramsPaging.limit}
            skipPerPage={paramsPaging.skip}
            typePaging={"remote"}
            height={Config.getHeightGrid()}
            onChangePage={onChangePage}
            onChangePerPage={onChangePerPage}
        >
            <Column cellRender={renderEmpProfile}/>
            <Column cellRender={renderInfo}/>
            <Column width={150} cellRender={renderStatus}/>
            <Column width={150} cellRender={renderAction}/>
        </GridContainer>
    </div>
}

export default compose(
    connect(
        (state) => ({
            getCboAppStatus: state.general.getCboAppStatus,
        }),
        (dispatch) => ({
            generalActions: bindActionCreators(generalActions, dispatch),
        })
    ),
    withStyles(styles, {withTheme: true})
)(W09F2002);