/**
 * @copyright 2020 @ DigiNet
 * @author HUYTRAN
 * @create 11/25/2020
 * @Example
 */
import React from "react";
import { bindActionCreators, compose } from "redux";
import { withStyles, IconButton, TextField, InputAdornment } from "@material-ui/core";
import { connect } from "react-redux";
import * as userActions from "../../../../redux/user/user_actions";
import Config from "../../../../config";
import Icons from "../../../common/icons/";
import propTypes from "prop-types";
import { Col, FormGroup, Row } from "react-bootstrap";
import { MForm } from "../../../common/form-material";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Modal, ModalHeader, ModalBody, Button, Notify } from "diginet-core-ui/components";

const styles = {
    titleAction: {
        fontWeight: "bold",
        fontSize: "0.75rem",
        color: "#555868",
    },
    description: {
        fontWeight: "normal",
        fontSize: "0.75rem",
        color: "#7F828E",
    },
    success: {
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#00C875",
        },
    },
};
class W09F2003 extends React.Component {
    constructor(props) {
        super(props);
        this.alertRef = React.createRef(null);
        this.state = {
            error: {},
            oldPassword: "",
            newPassword: "",
            confirmNewPassword: "",
            success: {},
            errorConfirm: false,
            isShowNewPass: false,
            isShowNewConfirmPass: false,
        };
    }

    // componentDidMount = async () => {
    //     //  await this.loadPermission();
    //     // if (!this.state.iPermission) return;
    // };
    setStateErrorText = value => {
        const { error } = this.state;
        this.setState({
            error: {
                ...error,
                ...value,
            },
        });
        return Object.keys(value).length !== 0;
    };
    handleChange = (key, e) => {
        if (!key || !e) return false;
        this.setState({ [key]: e.target.value });
    };
    onBlur = (key, e) => {
        const value = e.target.value;
        const { newPassword, confirmNewPassword } = this.state;
        const validateRules = [];
        switch (key) {
            case "oldPassword":
                if (value === "") {
                    validateRules.push({
                        name: "oldPassword",
                        rules: "isRequired",
                        value: value,
                    });
                }
                break;
            case "newPassword":
                validateRules.push({
                    name: "newPassword",
                    rules: "isPassword",
                    value: value,
                });
                break;
            case "confirmNewPassword":
                validateRules.push({
                    name: "confirmNewPassword",
                    rules: "isPassword",
                    value: value,
                });
                break;
            default:
                break;
        }
        const validateFrom = MForm.formValidation(validateRules);
        if (newPassword !== confirmNewPassword) {
            this.setState({
                errorConfirm: true,
            });
        }
        if (Object.keys(validateFrom).length > 0) {
            if (key)
                this.setState({
                    success: {
                        ...this.state.success,
                        [key]: false,
                    },
                });

            this.setStateErrorText(validateFrom);
            return false;
        } else {
            if (key)
                this.setState({
                    success: {
                        ...this.state.success,
                        [key]: true,
                    },
                });
            if (newPassword === confirmNewPassword) {
                this.setState({
                    errorConfirm: false,
                });
            }
            return false;
        }
    };
    onFormSubmit() {
        const { forgotPassword } = this.props;
        const { oldPassword, newPassword, confirmNewPassword } = this.state;
        const validateRules = forgotPassword
            ? [
                {
                    name: "newPassword",
                    rules: "isPassword",
                    value: newPassword,
                },
                {
                    name: "confirmNewPassword",
                    rules: "isPassword",
                    value: confirmNewPassword,
                },
            ]
            : [
                {
                    name: "oldPassword",
                    rules: "isRequired",
                    value: oldPassword,
                },
                {
                    name: "newPassword",
                    rules: "isPassword",
                    value: newPassword,
                },
                {
                    name: "confirmNewPassword",
                    rules: "isPassword",
                    value: confirmNewPassword,
                },
            ];
        const validateForm = MForm.formValidation(validateRules);
        if (this.setStateErrorText(validateForm)) return false;
        // if (this.state.oldPassword==='' ||this.state.newPassword==='' ||this.state.confirm==='')  return false;
        if (newPassword !== confirmNewPassword) {
            this.setState({
                errorConfirm: true,
            });
            return false;
        }
        this.setState(
            {
                errorConfirm: false,
                error: {},
                success: {},
            },
            () => {
                this.changePassword();
            }
        );
    }

    changePassword = async () => {
        const { forgotPassword, email, code } = this.props;
        const { oldPassword, newPassword } = this.state;
        if (forgotPassword) {
            let param = {
                NewPassword: newPassword,
                Email: email,
                Code: code
            };
            this.setState({ loading: true });
            await this.props.userActions.resetPassword(param, (error, data) => {
                this.setState({ loading: false });
                if (error) {
                    let message = error.message || Config.lang("Loi_chua_xac_dinh");
                    Config.popup.show("INFO", message);
                    return false;
                }
                if (data) {
                    if (data.Status === 1) {
                        let message = data.Message || Config.lang("Loi_chua_xac_dinh");
                        this.alertRef.current(message, {
                            color: "danger",
                            progressing: true,
                            autoDisappear: true,
                            clearAble: false,
                        });
                    } else {
                        this.alertRef.current(Config.lang("Doi_mat_khau_thanh_cong"), {
                            color: "success",
                            progressing: true,
                            autoDisappear: true,
                            clearAble: false,
                        });
                        setTimeout(() => {
                            Config.removeLocalStorage("PROFILEDHR");
                            Config.removeLocalStorage("TOKENDHR_LOGIN");
                            window.location.reload();
                        }, 5000);
                    }
                }
            });
        } else {
            let param = {
                oldPassword,
                newPassword,
            };
            this.setState({ loading: true });
            await this.props.userActions.changePassword(param, (error, data) => {
                this.setState({ loading: false });
                if (error) {
                    let message = error.message || Config.lang("Loi_chua_xac_dinh");
                    switch (error.code) {
                        case "US036":
                            message = Config.lang("Mat_khau_khong_dung_vui_long_thu_lai");
                            this.setState({
                                error: { oldPassword: message },
                                errorMessage: message,
                            });
                            break;
                        default:
                            Config.popup.show("INFO", message);
                            break;
                    }
                    return false;
                }
                if (data) {
                    Config.notify.show("success", Config.lang("Doi_mat_khau_thanh_cong"), 2000);
                    setTimeout(() => {
                        Config.removeLocalStorage("PROFILEDHR");
                        Config.removeLocalStorage("TOKENDHR_LOGIN");
                        window.location.reload();
                    }, 5000);
                }
            });
        }
    };
    handleClose = () => {
        const { onClose } = this.props;
        this.setState({
            error: {},
            oldPassword: "",
            newPassword: "",
            confirmNewPassword: "",
            success: {},
            errorMessage: "",
            errorConfirm: false,
            isShowNewPass: false,
            isShowNewConfirmPass: false,
        });
        if (onClose) onClose();
    };

    onShowPassword = id => {
        if (!id) return false;
        if (id === "newPassword") {
            this.setState({ isShowNewPass: !this.state.isShowNewPass });
        } else {
            this.setState({ isShowNewConfirmPass: !this.state.isShowNewConfirmPass });
        }
    };

    render() {
        const { classes, open, forgotPassword } = this.props;
        const { error, errorConfirm, success, errorMessage, oldPassword, newPassword, confirmNewPassword, isShowNewPass, isShowNewConfirmPass } = this.state;
        return (
            <>
                <React.Fragment>
                    <Modal
                        onClose={this.handleClose}
                        open={open}
                        pressESCToClose={false}
                        moveable={false}
                        dragAnyWhere={false}
                        moveOutScreen={false}
                        width={640}
                        zIndex={1300}
                    >
                        <ModalHeader title={Config.lang("Doi_mat_khau")} />
                        <ModalBody style={{ height: "100%" }}>
                            <div className="form-field display_col">
                                {!forgotPassword && (
                                    <FormGroup style={{ marginBottom: 10 }}>
                                        <Row>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <label className={classes.titleAction + " mgb0"}>{Config.lang("Mat_khau_cu")}</label>
                                                <TextField
                                                    id="oldPassword"
                                                    type="password"
                                                    autoComplete="current-password"
                                                    variant="outlined"
                                                    fullWidth
                                                    value={oldPassword}
                                                    onBlur={e => this.onBlur("oldPassword", e)}
                                                    onChange={e => this.handleChange("oldPassword", e)}
                                                    error={!!errorMessage ? !!errorMessage : oldPassword === "" && !!error.oldPassword}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                {oldPassword === "" && !!error.oldPassword ? <Icons name="error" /> : <div />}
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    helperText={(oldPassword === "" && error && error.oldPassword) || errorMessage}
                                                />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                )}
                                <FormGroup style={{ marginBottom: 10 }}>
                                    <Row>
                                        <Col xs={12} sm={12} md={12} lg={12}>
                                            <label className={classes.titleAction + " mgb0"}>{Config.lang("Mat_khau_moi")}</label>
                                            <TextField
                                                id="newPassword"
                                                type={isShowNewPass ? "text" : "password"}
                                                autoComplete="current-password"
                                                variant="outlined"
                                                value={newPassword}
                                                fullWidth
                                                onBlur={e => this.onBlur("newPassword", e)}
                                                onChange={e => this.handleChange("newPassword", e)}
                                                className={!!success.newPassword ? classes.success : null}
                                                error={!!error.newPassword && !success.newPassword}
                                                inputProps={{
                                                    autoComplete: "new-password",
                                                    form: {
                                                        autocomplete: "off",
                                                    },
                                                }}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            {!!error.newPassword && !success.newPassword ? (
                                                                <Icons name="error" className={"mgr5"} />
                                                            ) : success.newPassword ? (
                                                                <Icons name="success" className={"mgr5"} />
                                                            ) : (
                                                                <div />
                                                            )}
                                                            {!!newPassword && (
                                                                <IconButton href={""} size={"small"} onClick={() => this.onShowPassword("newPassword")}>
                                                                    {isShowNewPass ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                                                </IconButton>
                                                            )}
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </FormGroup>
                                <FormGroup style={{ marginBottom: 5 }}>
                                    <Row>
                                        <Col xs={12} sm={12} md={12} lg={12}>
                                            <span
                                                className={classes.description}
                                                style={!!error.newPassword && !success.newPassword ? { color: "#FF3D71" } : {}}
                                            >
                                                {Config.lang("Mat_khau_phai_co_it_nhat_8_ky_tu")}
                                            </span>
                                        </Col>
                                    </Row>
                                </FormGroup>
                                <FormGroup style={{ marginBottom: 5 }}>
                                    <Row>
                                        <Col xs={12} sm={12} md={12} lg={12}>
                                            <label className={classes.titleAction + " mgb0"}>{Config.lang("Xac_nhan_mat_khau_moi")}</label>
                                            <TextField
                                                id="confirmNewPassword"
                                                type={isShowNewConfirmPass ? "text" : "password"}
                                                autoComplete="current-password"
                                                variant="outlined"
                                                fullWidth
                                                value={confirmNewPassword}
                                                className={success.confirmNewPassword && !errorConfirm ? classes.success : null}
                                                onBlur={e => this.onBlur("confirmNewPassword", e)}
                                                onChange={e => this.handleChange("confirmNewPassword", e)}
                                                error={(!!error.confirmNewPassword && errorConfirm) || success.confirmNewPassword}
                                                helperText={
                                                    !!error.confirmNewPassword && errorConfirm ? Config.lang("Mat_khau_xac_nhan_khong_chinh_xac") : null
                                                }
                                                inputProps={{
                                                    autoComplete: "new-password",
                                                    form: {
                                                        autocomplete: "off",
                                                    },
                                                }}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            {!!error.confirmNewPassword && errorConfirm ? (
                                                                <Icons name="error" className={"mgr5"} />
                                                            ) : success.confirmNewPassword ? (
                                                                <Icons name="success" className={"mgr5"} />
                                                            ) : (
                                                                <div />
                                                            )}
                                                            {!!confirmNewPassword && (
                                                                <IconButton
                                                                    href={""}
                                                                    size={"small"}
                                                                    className={"mgr5"}
                                                                    onClick={() => this.onShowPassword("confirmNewPassword")}
                                                                >
                                                                    {isShowNewConfirmPass ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                                                </IconButton>
                                                            )}
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </FormGroup>
                                <FormGroup className="display_row align_center valign-bottom mgt25">
                                    <Button
                                        viewType="filled"
                                        text={forgotPassword ? Config.lang("Luu") : Config.lang("Dong_y")}
                                        style={{ textTransform: "uppercase", minWidth: 105 }}
                                        color="info"
                                        onClick={() => this.onFormSubmit()}
                                    />
                                    {forgotPassword && (
                                        <Button
                                            viewType="outlined"
                                            text={Config.lang("Huy")}
                                            style={{ textTransform: "uppercase", minWidth: 105, marginLeft: 8 }}
                                            onClick={() => this.handleClose()}
                                        />
                                    )}
                                </FormGroup>
                            </div>
                        </ModalBody>
                    </Modal>
                </React.Fragment>
                <Notify getAddNotify={ref => (this.alertRef.current = ref)} />
            </>
        );
    }
}

W09F2003.propType = {
    open: propTypes.bool,
    onClose: propTypes.func,
};

export default compose(
    connect(
        state => ({}),
        dispatch => ({
            userActions: bindActionCreators(userActions, dispatch),
        })
    ),
    withStyles(styles)
)(W09F2003);
